<template>
  <div>
    <!-- ichgi tab -->
    <div></div>
    <div class="my-navBar" v-if="!showSideBar"  :class="mode ? '' : 'table__mynight'">
      <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        :collapse="showSideBar"
        :class="mode ? '' : 'performance__night'"
      >
        <el-input
          placeholder="Поиск"
          prefix-icon="el-icon-search"
          v-model="searchName"
          :class="mode ? 'input__day' : 'input__night'"
        >
        </el-input>
        <el-checkbox-group v-model="department_ids" @change="triggerChanges">
          <el-menu-item
            v-for="department in items"
            :key="'index' + department.id"
          >
            <el-checkbox :label="department.id" :class="mode ? 'text__day2' : 'text__night2'">
              {{ department.name }}
             
            </el-checkbox>
             <span v-show="is_current" style="font-size: 10px;" :class="mode ? 'text__day2' : 'text__night2'"> 
                 <span :class="mode ? 'text__day2' : 'text__night2'">({{
                  department.number_staff != null
                    ? department.number_staff
                    : "0"
                }}</span> / <span style="color:#409eff;" >{{ department.staff_count }}</span> )
              </span>
          </el-menu-item>
        </el-checkbox-group>
      </el-menu>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      checked: "",
      department_ids: [],
      searchName: "",
    };
  },
  props: {
    showSideBar: {
      type: Boolean,
      default: true,
      required: true,
    },
    company_id: {
      required: false,
    },
    is_current: {
      default: false,
    },
  },
  watch: {
    showSideBar: {
      handler: function () {},
      deep: true,
      immediate: true,
    },
    company_id: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.department_ids = [];
          this.$emit("triggredChanges", this.department_ids);
        }
      },
      deep: true,
      immediate: true,
    },
    department_ids: {
      handler: function (newVal, oldVal) {},
      deep: true,
      immediate: true,
    },
    searchName: {
      handler: function () {},
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      departments: "department/inventory",
    }),
    items: {
      get: function () {
        let departments = this.departments;
        if (this.searchName) {
          departments = departments.filter((item) => {
            let byName =
              item.name.toLowerCase().indexOf(this.searchName.toLowerCase()) >
              -1;
            if (byName === true) {
              return byName;
            }
          });
        }
        if (!this.company_id) {
          return departments;
        }
        return _.filter(departments, ["company_id", this.company_id]);
      },
      set: function () {},
    },
  },
  methods: {
    ...mapActions({
      updateInventory: "department/inventory",
    }),
    triggerChanges(value) {
      this.$emit("triggredChanges", value);
      if (value.length == 1) {
        this.$emit("setDepartment", _.find(this.departments, ["id", value[0]]));
      } else {
        this.$emit("setDepartment", {
          id: null,
          branch_id: null,
          company_id: null,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.performance__night{
  .el-menu-item:focus, .el-menu-item:hover{
    outline: 0;
    background-color: #4f5660;
  }

}
</style>
